import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import * as React from "react";
import { ChangeEvent, Fragment, useState } from "react";
import { enqueueSnackbar } from "../../dataStore/features/notifierSlice";
import { store } from "../../dataStore/store";

interface NProps {
  open: boolean,
  setOpen: Function,
  keys?: string[],
  onUpdate: Function,
}

interface iFieldType {
  type: string,
  label: string,
}

const fieldTypes: iFieldType[] = [
  {type: `text`, label: `Text`},
  {type: `number`, label: `Number`},
  {type: `emailList`, label: `Email list`},
  {type: `boolean`, label: `Boolean`},
  {type: `section`, label: `Section`},
]

export const AddNewItem = ({open, setOpen, keys, onUpdate}: NProps) => {
  const [fieldType, setFieldType] = useState<string>('boolean');
  const [inputKeys, setInputKeys] = useState<string | null>(null);
  const [inputValues, setInputValues] = useState<string | string[] | number | null>(null);
  const [inputBool, setInputBool] = useState<boolean>(false);

  const onAdding = () => {
    if (inputKeys === null) {
      return store.dispatch(enqueueSnackbar({type: "error", message: "Please add a key"}))
    } else if (fieldType !== 'boolean' && inputValues === null) {
      return store.dispatch(enqueueSnackbar({type: "error", message: "Please add value"}))
    } else {
      let tempList = [...keys!];
      const _k = inputKeys!.toLowerCase()
        .replace(/\s+/g, ' ')
        .replace('.', '')
        .trim().split(' ').join('_');
      tempList.push(_k);
      setOpen(false);
      switch (fieldType) {
        case 'section':
          return onUpdate(tempList, {});
        case 'boolean':
          return onUpdate(tempList, inputBool);
        default:
          return onUpdate(tempList, inputValues)
      }
    }
  };

  return (
    <Dialog
      open={open}
      keepMounted={false}
      maxWidth={'xs'}
      aria-describedby="flavor-delete-action-confirmation-dialog"
      sx={{
        textAlign: 'center',
      }}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <DialogTitle>Add New Field</DialogTitle>
        </Grid>
        <Grid item>
          <Button autoFocus onClick={() => setOpen(!open)} sx={{mr: 2}}>Close</Button>
        </Grid>
      </Grid>
      <DialogContent>
        <Box sx={{
          mb: 1,
        }}>
          <TextField
            variant={"standard"}
            label={'Key'}
            sx={{width: `100%`}}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInputKeys(e.target.value)}
          />
        </Box>
        <Grid container spacing={1} alignItems={"center"} justifyContent={"space-between"}>
          <Grid item sx={{flex: 0}}>
            <Typography sx={{fontSize: 13, whiteSpace: 'nowrap'}} color="text.secondary">Select field type</Typography>
          </Grid>
          <Grid item sx={{flex: 0}}>
            <Select
              value={fieldType}
              onChange={(e: SelectChangeEvent) => setFieldType(e.target.value)}
              variant={"standard"}
            >
              {fieldTypes.map(({type, label}: iFieldType) => <MenuItem value={type}>{label}</MenuItem>)}
            </Select>
          </Grid>
        </Grid>
        {
          fieldType === 'section'
            ? <Fragment/>
            : fieldType === 'boolean'
              ? <Grid container spacing={1} alignItems={"center"} justifyContent={"space-between"}>
                <Grid item>
                  <Typography
                    sx={{fontSize: 13, whiteSpace: 'nowrap'}}
                    color="text.secondary">Field status</Typography>
                </Grid>
                <Grid item>
                  <Switch checked={inputBool} onChange={(e, checked) => setInputBool(checked)}/>
                </Grid>
              </Grid>
              : <TextField
                required
                variant={"standard"}
                label={'Value'}
                sx={{width: `100%`}}
                type={fieldType === 'number' ? "number" : "text"}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const val = e.target.value;
                  const _st = () => {
                    switch (fieldType) {
                      case "number":
                        return parseInt(val);
                      case "emailList":
                        return val.split(',').map((v: string) => v.trim());
                      default:
                        return val;
                    }
                  }
                  setInputValues(_st());
                }}
              />
        }
      </DialogContent>

      <DialogActions sx={{p: 0}}>
        <ButtonGroup
          variant="contained"
          aria-label="Flavor delete actions"
          sx={{
            flex: 1,
            '> button': {
              flex: 1,
              borderRadius: 0,
            }
          }}
          color={'inherit'}
        >
          <Button color="primary" variant={'contained'} onClick={onAdding}>ADD</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  )
}